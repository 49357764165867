<template>
  <main
    id="site-wrapper"
  >
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{name: 'admin'}">
              Admin
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{name: 'operational-dashboard'}">
              Operational Dashboard
            </router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ componentTitle | titleFormat }}
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'operational-dashboard'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>
              {{ componentTitle | titleFormat }}
            </h1>
          </div>
        </div>

        <SelectDates
          v-if="areDatesLoaded"
          :initial-range="range"
          :updating="isUpdating"
          @get-data="getData"
        />

        <div class="charts-wrapper">
          <div
            id="popular-protocols"
            class="section-panel"
          >
            <div class="section-panel-header">
              <div class="section-panel-header-title">
                <h3>Conferences and Participants by Week</h3>
              </div>
            </div>

            <div class="section-panel-body">
              <div
                v-if="isLoading"
                class="isLoading"
              >
                loading data...
              </div>

              <div
                v-if="!isLoading && currentMeetingByDay"
                class="totals"
              >
                Total Conferences <em>{{ currentMeetingByDay.total_conferences }}</em>  -  Total Participants <em>{{ currentMeetingByDay.total_participants }}</em>
              </div>

              <div
                v-if="!isLoading && chartLabels"
                ref="chartContainer"
                class="chart-container"
              >
                <LineChart
                  :start-date="dateStart"
                  :end-date="dateEnd"
                  :custom-labels="chartLabels"
                  :custom-data-set="chartDataSet"
                  label-alignment="right"
                  :max-ticks="10"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper">
          <div
            id="endpoints-table"
            class="section-panel"
          >
            <div class="section-panel-header">
              <div class="section-panel-header-title">
                <h3>{{ componentTitle | titleFormat }}</h3>
              </div>
              <div
                v-if="range.start"
                class="section-panel-header-action date-range"
              >
                {{ dateStart | dateFormat }} - {{ dateEnd | dateFormat }}
              </div>
            </div>
            <div class="section-panel-body">
              <div
                v-if="isLoadingData"
                class="isLoading"
              >
                loading data...
              </div>

              <div
                v-if="!isLoadingData"
              >
                <b-table
                  v-if="tableDataSet"
                  class="meetingsByDateTable"
                  responsive
                  striped
                  hover
                  :items="tableDataSet"
                  :fields="tableDataFields"
                >
                  <template #cell(total_duration)="row">
                    {{ row.item.total_duration | HoursMinsSecs }}
                  </template>

                  <template #cell(average_duration)="row">
                    {{ row.item.average_duration | HoursMinsSecs }}
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {brandingStore} from '@/store/__STORE_branding';
import {meetingStore} from '@/store/__STORE_meetings';
import ChartDataMixin from '@/mixins/ChartDataMixin';
import DateFormatMixin from '@/mixins/DateFormatMixin';
import OpsDashMixin from '@/mixins/OpsDashMixin';
import LineChart from './components/charts/MyLineGraph.js';

import SelectDates from './components/SelectDates.vue';

export default {
  name: 'EndpointsDrilldown',
  components: {
    LineChart,
    SelectDates,
  },
  mixins: [
    ChartDataMixin,
    DateFormatMixin,
    OpsDashMixin,
  ],
  data() {
    return {
      componentTitle: this.$route.name,
      chartWidth: 100,
      tableDataFields: [
        {
          key: 'date',
          label: 'Date',
          class: 'date',
        },
        {
          key: 'conferences',
          label: 'Conferences',
        },
        {
          key: 'rooms_count',
          label: 'Rooms',
        },
        {
          key: 'total_duration',
          label: 'Total Duration',
        },
        {
          key: 'average_duration',
          label: 'Average Duration',
        },
        {
          key: 'total_participants',
          label: 'Total Participants',
        },
        {
          key: 'average_participants',
          label: 'Average Participants',
        },
      ],
      tableDataSet: [],
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'DD-MM-YYYY',
      },
      fromMax: null,
      toMin: null,
      attributes: [],
      chartLabels: null,
      chartDataSet: [
        {
          label: 'Conferences',
          borderColor: brandingStore.__GETTERdefaultPrimary,
          borderWidth: 3,
          pointHoverBorderWidth: 4,
          backgroundColor: 'rgb(0,0,0,0)',
          lineTension: 0.2,
          pointRadius: 13,
          pointHoverRadius: 13,
          pointBackgroundColor: 'white',
          pointHoverBackgroundColor: 'white',
          data: [],
        },
        {
          label: 'Participants',
          borderColor: brandingStore.__GETTERdefaultSecondary,
          borderWidth: 3,
          pointHoverBorderWidth: 4,
          backgroundColor: 'rgb(0,0,0,0)',
          lineTension: 0.2,
          pointRadius: 13,
          pointHoverRadius: 13,
          pointBackgroundColor: 'white',
          pointHoverBackgroundColor: 'white',
          data: [],
        },
      ],
      polling: null,
      isUpdating: false,
    };
  },
  computed: {
    isLoading() {
      return (!this.currentMeetingByWeek.conferences_by_weeks ? true : false);
    },
    isLoadingData() {
      return (!this.currentMeetingByDay.conferences_by_dates ? true : false);
    },
    areDatesLoaded() {
      return this.range.start && this.range.end;
    },
    tableData() {
      return this.currentMeetingByDay.conferences_by_dates;
    },
    chartData() {
      return this.currentMeetingByWeek.conferences_by_weeks;
    },
    dateStart() {
      return this.currentMeetingByDay.from_date;
    },
    dateEnd() {
      return this.currentMeetingByDay.to_date;
    },
    lineChartStyles() {
      return {
        height: '#{this.chartWidth}px',
        width: '#{this.chartWidth}px',
        position: 'relative',
      };
    },
    currentMeetingByDay() {
      return meetingStore.meetingsByDateData;
    },
    currentMeetingByWeek() {
      return meetingStore.meetingsByDateChart;
    },
  },
  watch: {
    tableData: function() {
      this.isUpdating = false;
      this.tableDataSet = this.tableData;
      this.updateDates(this.formatDateYMD(this.currentMeetingByDay.from_date), this.formatDateYMD(this.currentMeetingByDay.to_date));
    },
    chartData: function() {
      this.chartLabels = this.getWeekLabels(this.chartData).reverse();
      this.chartDataSet[0].data = this.getPropValues(this.chartData, 'conferences').reverse();
      this.chartDataSet[1].data = this.getPropValues(this.chartData, 'total_participants').reverse();
    },
  },
  created() {
    this.getData();
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    getData(start, end) {
      if (start || end) {
        this.updateDates(start, end);
      }
      this.isUpdating = true;
      meetingStore.getMeetingsByDateData(this.createDates(this.range.start, this.range.end));
      meetingStore.getMeetingsByDateChart(this.createDates(this.range.start, this.range.end));
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/__variables";

  .container-fluid {
    max-width: $container-width;
  }

  .section-panel {
    display: flex;
    flex-direction: column;

    .isLoading {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      padding: 2rem;
    }
  }

  .totals {
    font-size: 1.7rem;

    em {
      font-style: normal;
      font-weight: bold;
    }
  }

  .charts-wrapper,
  .table-wrapper {
    margin-top: 1rem;
  }
  .meetingsByDateTable ::v-deep .date {
    white-space: nowrap;
  }
  .meetingsByDateTable ::v-deep td {
    padding: 1.275rem;
  }
</style>
