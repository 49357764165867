import {Line} from 'vue-chartjs';

export default {
  extends: Line,
  props: {
    startDate: String,
    endDate: String,
    customLabels: Array,
    customDataSet: Array,
    labelAlignment: String,
    maxTicks: Number,
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontSize: 16,
          },
          position: 'top',
          align: 'end',
        },
        scaleFontSize: 26,
        scales: {
          fontSize: 26,
          xAxes: [{
            ticks: {
              autoSkip: true,
              maxTicksLimit: this.maxTicks,
              maxRotation: 0,
              minRotation: 0,
              fontSize: 14,
              padding: 15,
            },
            gridLines: {
              display: true,
            },
          }],
          yAxes: [{
            ticks: {
              display: true,
              suggestedMin: 0,
              precision: 0,
            },
            gridLines: {
              drawBorder: false,
              borderDash: [2, 5],
            },
          }],
        },
        tooltips: {
          fontSize: 14,
          enabled: true,
          intersect: false,

          callbacks: {
            label: function(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const xLabel = data.datasets[tooltipItem.datasetIndex].label;
              const currentValue = dataset.data[tooltipItem.index];
              return currentValue + ' ' + xLabel.toLowerCase();
            },
            labelColor: function(tooltipItem, chart) {
              return {
                borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
              };
            },
            labelTextColor: function(tooltipItem, chart) {
              return 'white';
            },
          },
        },
      },
    };
  },
  computed: {
    dateStart() {
      return this.startDate;
    },
    dateEnd() {
      return this.endDate;
    },
    DataLabels: function() {
      return this.customLabels;
    },
    ChartDataSets: function() {
      return this.customDataSet;
    },
  },
  watch: {
    dateStart: function() {
      this.showChart();
    },
    dateEnd: function() {
      this.showChart();
    },
  },
  mounted() {
    this.showChart();
  },
  methods: {
    showChart: function() {
      if (this._chart) {
        this._chart.destroy();
      }
      this.renderChart(
          {
            labels: this.DataLabels,
            datasets: this.ChartDataSets,
          },
          this.options,
      );
    },
  },
};
